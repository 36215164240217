import bem from "bem-ts"
import { fetchRequest } from "~/helpers/fetch"

const b = bem("tabs", { strict: false }),
    clearTheContentsOfTheOldTab = true

const toggleMenuTagEvent: Event = new Event('toggleMenuTag', {
    bubbles: true,
    cancelable: true,
    composed: false
})

window.addEventListener('DOMContentLoaded', () => {
    const tabContainer = document.querySelectorAll('.tabs') as NodeListOf<HTMLElement>

    if (tabContainer.length) {
        tabContainer.forEach((moreLink: HTMLElement) => moreLink.addEventListener('click', (event: Event) => clickHandler(event)))
    }
})

const clickHandler = (event: Event) => {
    const target = event.target as HTMLElement

    if (target.classList.contains(b('link-more'))) {
        toggleLinks(event, target)
    } else if (target.classList.contains(b('link'))) {
        toggleTab(event, target)
    }

    return void 0
}

const toggleTab = (event: Event, linkContainer: HTMLElement) => {
    event.preventDefault()

    const linksListContainer = linkContainer.closest<HTMLElement>('.' + b('links-list'))

    if (!linksListContainer) return void 0

    const activeTabContainer = linksListContainer.querySelector<HTMLElement>('.' + b('link--selected'))

    if(!activeTabContainer) return void 0

    const idOfTheOldActiveTab = activeTabContainer.dataset.tab as number | undefined

    activeTabContainer.classList.remove(b('link--selected'))
    linkContainer.classList.add(b('link--selected'))

    const idOfTheNewActiveTab = linkContainer.dataset.tab as number | undefined

    if (idOfTheNewActiveTab === undefined || idOfTheOldActiveTab === undefined) return void 0

    toggleTabContent(idOfTheNewActiveTab, idOfTheOldActiveTab, linkContainer)
}

const toggleTabContent = async (idOfTheNewActiveTab: number, idOfTheOldActiveTab: number, linkContainer: HTMLElement) => {
    const mainMenuSublevel = linkContainer.closest<HTMLElement>('.main-menu-sublevel')

    if (!mainMenuSublevel || idOfTheNewActiveTab == idOfTheOldActiveTab) return void 0

    const newActiveMenuSublevelListContainer = mainMenuSublevel.querySelector('.main-menu-sublevel__list[data-tab-id="' + idOfTheNewActiveTab +'"]'),
        oldActiveMenuSublevelListContainer = mainMenuSublevel.querySelector('.main-menu-sublevel__list[data-tab-id="' + idOfTheOldActiveTab +'"]'),
        mainMenuSublevelId = mainMenuSublevel.dataset.menuSublevelId,
        idGroup = mainMenuSublevel.dataset.idGroup

    if (!newActiveMenuSublevelListContainer || !oldActiveMenuSublevelListContainer || !mainMenuSublevelId || !idGroup) return void 0

    const issetActiveTabContent = newActiveMenuSublevelListContainer.querySelector('.main-menu-sublevel__item ')

    if (!issetActiveTabContent) {
        const response = await fetchRequest(`/main-menu/group/${idGroup}/btmegamenu/${mainMenuSublevelId}/tag/${idOfTheNewActiveTab}/`, {})

        if (!response.success || !response.data) return void 0

        newActiveMenuSublevelListContainer.innerHTML = response.data.html
    }

    if (clearTheContentsOfTheOldTab) {
        oldActiveMenuSublevelListContainer.innerHTML = ""
    }

    newActiveMenuSublevelListContainer.classList.remove('hidden')
    oldActiveMenuSublevelListContainer.classList.add('hidden')

    const selectedTab = document.querySelector(`.tabs__link--selected[data-tab="${idOfTheNewActiveTab}"]`)

    if (selectedTab) selectedTab.dispatchEvent(toggleMenuTagEvent)
}

const toggleLinks = (event: Event, moreLink: HTMLElement) => {
    if (moreLink.classList.contains(b('link-more--less'))) {
        moreLink.classList.remove(b('link-more--less'))
        moreLink.innerHTML = "ещё"

        const showLinks = moreLink.parentElement?.querySelectorAll('.' + b('link--show')) as NodeListOf<HTMLElement>
        showLinks.forEach((showLink: HTMLElement) => {
            showLink.classList.remove(b('link--show'))
        })

        return void 0
    }

    moreLink.classList.add(b('link-more--less'))
    moreLink.innerHTML = "скрыть"

    const showLinks = moreLink.parentElement?.querySelectorAll('.' + b('link')) as NodeListOf<HTMLElement>
    showLinks.forEach((showLink: HTMLElement) => {
        showLink.classList.add(b('link--show'))
    })
}
