import { createApp }                    from 'vue'
import { createBem }                    from '@vuebits/bem'
import Maska                            from 'maska'
import TheHeaderHandler                 from '~vue/header/TheHeaderHandler.vue'
import TheHeaderCallbackForm            from "~vue/header/TheHeaderCallbackForm.vue"
import FormsHandlerBulk                 from '~vue/forms/FormsHandlerBulk.vue'
import SearchForm                       from '~vue/search/SearchForm.vue'
import CallbackFormProfit               from '~vue/profit-buy-forms/CallbackFormProfit.vue'
import TheCartDeliveryWithMapWrapper    from '~vue/cart/delivery/TheCartDeliveryWithMapWrapper.vue'
import PersonalAreaFirstLoginPopup      from "~vue/personal-area/components/PersonalAreaFirstLoginPopup.vue"


// header
createApp(TheHeaderHandler)
    .use(
        createBem({
            hyphenate: true,
        })
    )
    .use(Maska)
    .mount('#header-mount')
createApp(TheHeaderCallbackForm)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#back-call')
createApp(CallbackFormProfit)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#back-call-profit')

//catalog input search
window.addEventListener('load', () => {
    const container = document.querySelector('#search-catalog-input') as HTMLElement
    if (!container) return
    const isCatalog = container.dataset.isCatalog

    createApp(SearchForm, { isCatalog })
        .use(createBem({
            hyphenate: true
        }))
        .use(Maska)
        .mount('#search-catalog-input')
})

// Default Forms
// TODO: сомнительное решение, потому что мне показали разные тесты как и увеличение, так и снижение баллов
window.addEventListener("load", () => {
    createApp(FormsHandlerBulk, {
        componentsList: [
            {
                component: "TheFormsDetailPageCallbackFormAsync",
                mountSelector: "#detail-page-questions-inline",
                relation: "",
            },
            {
                component: "TheFormsDetailPageCallbackFormAsync",
                mountSelector: "#detail-page-interior-inline",
                relation: "",
            },
            {
                component: "FormsTwoColumnsFormAsync",
                mountSelector: "#design-request-inline",
                relation: "",
            },
            {
                component: "FormsTwoColumnsFormAsync",
                mountSelector: "#corp-clients-request-inline",
                relation: "",
            },
            {
                component: "FormsTwoColumnsFormAsync",
                mountSelector: "#tenders-request-inline",
                relation: "",
            },
            {
                component: "FormsCallbackFormAsync",
                mountSelector: "#callback-inline",
                relation: "",
            },
            {
                component: "FormsCallbackFormAsync",
                mountSelector: "#callback-modal",
                relation: "callback-modal",
            },
            {
                component: "FormsPopupSubscription",
                mountSelector: "#popup-subscription-form",
                relation: "",
            },
            {
                component: "FormsFooterSubscription",
                mountSelector: "#footer-subscription-form",
                relation: "",
            },
            {
                component: 'FormsNewsletterSubscription',
                mountSelector: '#subscribe-newsletter',
                relation: ''
            },
            {
                component: 'FormsCallbackFormAsync',
                mountSelector: '#profit-page-questions-inline',
                relation: ''
            },
            {
                component: 'SubscriptionFormProfit',
                mountSelector: '#subscription-profit',
                relation: ''
            },
            {
                component: 'FormsCallbackFormAsync',
                mountSelector: '#empty-search-results-form',
                relation: ''
            },
            // {
            //     component: 'TheCartDeliveryWithMapWrapper',
            //     mountSelector: '#bottom-map',
            //     relation: '',
            // },
        ]
    })
        .use(
            createBem({
                hyphenate: true,
            })
        )
        .use(Maska)
        .mount('#global-forms-mount')

    // пока жидкое решение, т.к. надо срочно и вчера, по хорошему нужно переписать карты под возможность разделения
    let bottomMapCoomponent = createApp(TheCartDeliveryWithMapWrapper)
    bottomMapCoomponent.use(Maska)
    bottomMapCoomponent.use(createBem({
        hyphenate: true
    }))
    bottomMapCoomponent.mount('#bottom-map')


    const modalDeliveryPopup = document.querySelector('.dropdown-screen[data-relation="modal-delivery"]')
    const bottomMap = document.querySelector('#bottom-map')

    if (modalDeliveryPopup && bottomMap) {
        modalDeliveryPopup.addEventListener('dropdownClose', () =>{
            bottomMapCoomponent.unmount()

            bottomMapCoomponent = createApp(TheCartDeliveryWithMapWrapper)
            bottomMapCoomponent.use(Maska)
            bottomMapCoomponent.use(createBem({
                hyphenate: true
            }))
            bottomMapCoomponent.mount('#bottom-map')

        })
    }
})

if (document.querySelector<HTMLElement>("#popup-first-login")) {
    createApp(PersonalAreaFirstLoginPopup).mount("#popup-first-login")
}
