export const tooltipButton = (element: Element) => {
    const activeClass = 'tooltip-button--active'
    const button = element.querySelector('.tooltip-button__button')

    let isActive = false

    const clickOutsideHandler = (ev: MouseEvent) => {
        const target = ev.target as HTMLElement

        if (!target.classList.contains('tooltip-button__content')) {
            isActive = false
            element.classList.remove(activeClass)
            document.removeEventListener('click', clickOutsideHandler)
        }
    }

    button?.addEventListener('click', () => {
        isActive = !isActive
        element.classList[isActive ? 'add' : 'remove'](activeClass)

        if (isActive) setTimeout(() => {
            document.addEventListener('click', clickOutsideHandler)
        })
    })
}

const buttons = document.querySelectorAll('.tooltip-button')
buttons.forEach(tooltipButton)
